<template>
  <b-col cols="6" md="4" xl="3">
    <b-card
      text-variant="center"
      bg-variant="warning"
      class="cursor-pointer"
      @click="goShop()"
    >
      <b-avatar variant="warning" size="70" class="shadow mb-2">
        <feather-icon size="28" icon="ShoppingCartIcon" />
      </b-avatar>
      <h1 class="mb-1 mt-50 text-white">
        {{ $t("homepage.shop_message") }}
      </h1>
      <b-card-text class="m-auto w-75 text-white">
        {{ $t("homepage.shop_title") }}
      </b-card-text>
    </b-card>
  </b-col>
</template>

<script>
import { BCol, BCard, BAvatar, BCardText } from "bootstrap-vue";

export default {
  name: "homepage-shop-shortcut",
  components: {
    BCol,
    BCard,
    BAvatar,
    BCardText,
  },
  methods: {
    goShop() {
      this.$router.push({
        name: "/Shop/Shop",
      });
    },
  },
};
</script>