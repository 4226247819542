<template>
  <b-col cols="6" md="4" xl="3">
    <b-card
      text-variant="center"
      bg-variant="success"
      class="cursor-pointer"
      @click="goPay()"
    >
      <b-avatar variant="success" size="70" class="shadow mb-2">
        <feather-icon size="28" icon="CreditCardIcon" />
      </b-avatar>
      <h1 class="mb-1 mt-50 text-white">
        {{ $t("homepage.pay_message") }}
      </h1>
      <b-card-text class="m-auto w-75 text-white">
        {{ $t("homepage.pay_title") }}
      </b-card-text>
    </b-card>
  </b-col>
</template>

<script>
import { BCol, BCard, BAvatar, BCardText } from "bootstrap-vue";

export default {
  name: "homepage-pay-shortcut",
  components: {
    BCol,
    BCard,
    BAvatar,
    BCardText,
  },
  methods: {
    goPay() {
      this.$router.push({
        name: "/Payment/Pay",
      });
    },
  },
};
</script>