<template>
  <b-col cols="12" md="6">
    <b-card no-body>
      <b-card-header class="d-flex justify-content-between">
        <h3>{{ $t('homepage.lt.title') }}</h3>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="none" size="sm" @click="getData()">
          <i class="fal fa-sync-alt text-primary" />
        </b-button>
      </b-card-header>
      <b-card-body>
        <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
          <b-table :fields="fields" :items="data" small responsive v-if="ready && data.length > 0">
            <template #cell(company_name)="data">
              <b-link
                :to="{
                  name: '/Company/Display',
                  params: { id: data.item.company_id }
                }"
                target="_blank"
              >
                <p>{{ data.item.company_name }}</p>
              </b-link>
            </template>
            <template #cell(transaction_date)="data">
              {{ showDate(data.item.transaction_date) }}
            </template>
            <template #cell(amount)="data">
              {{ priceTL(data.item.total_amount) }}
            </template>
            <template #cell(transaction_type)="data">
              <b-badge :variant="typeVariant(data.item.transaction_type)">
                {{ $t('homepage.lt.type_' + data.item.transaction_type) }}
              </b-badge>
            </template>
            <template #cell(transaction_state)="data">
              <b-badge :variant="stateVariant(data.item.transaction_state)">
                {{ $t('homepage.lt.state_' + data.item.transaction_state) }}
              </b-badge>
            </template>
            <template #cell(erp_description)="data">
              <feather-icon
                v-if="data.item.erp_master_id"
                icon="CheckCircleIcon"
                size="21"
                class="text-success"
                v-b-tooltip.html.hover.v-primary
                :title="`ERP ID: ${data.item.erp_master_id}${data.item.erp_description ? '<br/>Detay ' + data.item.erp_description : ''}`"
              />
              <feather-icon v-else icon="XCircleIcon" size="21" class="text-danger" />
            </template>
            <template #cell(process_area)="data">
              <b-link
                v-if="data.item.transaction_id"
                :to="{
                  name: '/Payment/TransactionDetail',
                  params: { id: data.item.transaction_id }
                }"
                target="_blank"
              >
                <i class="fal fa-receipt"></i>
              </b-link>
            </template>
          </b-table>
          <b-alert variant="warning" :show="ready" v-else>
            <div class="alert-body">
              <span>
                {{ $t('homepage.lt.not_found') }}
              </span>
            </div>
          </b-alert>
        </b-overlay>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<style lang="scss">
.fa-sync-alt {
  font-size: 1.25rem;
}
.company-name {
  max-width: 250px;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
  }
}
</style>

<script>
import { BCol, BTable, BOverlay, BBadge, BButton, BCard, BCardBody, BCardHeader, BLink, BAlert, VBTooltip } from 'bootstrap-vue'
import { GET_LAST_TRANSACTIONS } from '@/store/services/homePage-service'
import { mapGetters } from 'vuex'
import { priceFormat } from '@/assets/js/functions/currency-functions'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'last-transactions',
  components: {
    BCol,
    BTable,
    BOverlay,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BLink,
    BAlert
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      data: undefined,
      ready: false
    }
  },
  created() {
    this.getData()
  },
  computed: {
    ...mapGetters(['getLocale']),
    fields() {
      return [
        {
          key: 'company_name',
          label: this.$t('homepage.lt.company_name'),
          tdClass: 'company-name',
          thClass: 'company-name'
        },
        {
          key: 'transaction_type',
          label: this.$t('homepage.lt.transaction_type'),
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap'
        },
        {
          key: 'transaction_state',
          label: this.$t('homepage.lt.transaction_state'),
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap'
        },
        {
          key: 'erp_description',
          label: this.$t('homepage.lt.erp_description'),
          tdClass: 'text-center text-nowrap',
          thClass: 'text-center text-nowrap'
        },
        {
          key: 'amount',
          label: this.$t('homepage.lt.amount'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        },
        {
          key: 'transaction_date',
          label: this.$t('homepage.lt.transaction_date'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          key: 'process_area',
          label: ''
        }
      ]
    }
  },
  methods: {
    getData() {
      this.ready = false
      this.$store.dispatch(GET_LAST_TRANSACTIONS, 10).then(response => {
        this.data = response
        this.ready = true
      })
    },
    showDate(date) {
      return new Intl.DateTimeFormat(this.getLocale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(new Date(date))
    },
    priceTL(amount) {
      return priceFormat(amount, this.getLocale, 'TRY')
    },
    typeVariant(type) {
      switch (type) {
        case 0:
          return 'light-success'
        case 1:
          return 'light-warning'
        case 2:
          return 'light-danger'
      }
    },
    stateVariant(state) {
      switch (state) {
        case 0:
          return 'light-warning'
        case 1:
          return 'light-success'
        case 2:
          return 'light-danger'
      }
    }
  }
}
</script>
