<template>
  <section>
    <div v-if="$can('manage', '/Home/Home')">
      <b-row>
        <home-welcome />
        <home-shop-shortcut v-if="$can('manage', '/Shop/Shop')" />
        <home-pay-shortcut v-if="$can('manage', '/Payment/Pay')" />
      </b-row>
      <b-row>
        <home-last-transactions v-if="$can('manage', '/Dashboard/GetTransaction')" />
        <home-last-orders v-if="$can('manage', '/Dashboard/GetOrder')" />
      </b-row>
    </div>
    <b-row v-else>
      <b-col cols="12">
        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              {{ $t("NotAuthorizedTitle") }}
            </h2>
            <p class="mb-2">
              {{ $t("NotAuthorizedMessage") }}
            </p>
            <b-button variant="danger" class="btn-md" @click="logout()">
              {{ $t("Log Out") }}
            </b-button>
            <b-img :src="imgUrl" alt="Not authorized page" class="d-flex mx-auto" />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-table :items="table" v-if="false">
      <template #cell(pages)="data">
        <ul>
          <li v-for="(page, index) in data.item.pages" :key="index">
            {{ page.method_name }}
          </li>
        </ul>
      </template>
      <template #cell(methods)="data">
        <ul>
          <li v-for="(method, index) in data.item.methods" :key="index">
            {{ method.method_name }}
          </li>
        </ul>
      </template>
    </b-table>
  </section>
</template>

<script>
import { BRow, BCol, BImg, BButton, BTable } from "bootstrap-vue";
import HomeWelcome from "./Welcome.vue";
import HomeShopShortcut from "./ShopShortcut.vue";
import HomePayShortcut from "./PayShortcut.vue";
import HomeLastTransactions from "./LastTransactions.vue";
import HomeLastOrders from "./LastOrders.vue";
import { PURGE_AUTH } from "@/store/services/auth-service";
import { GET_ALL_METHODS } from "@/store/services/module-service";

export default {
  name: "homepage",
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BTable,

    HomeWelcome,
    HomeShopShortcut,
    HomePayShortcut,
    HomeLastTransactions,
    HomeLastOrders,
  },
  data() {
    return {
      table: undefined,
      downImg: require("@/assets/images/pages/not-authorized.svg"),
    };
  },
  mounted() {
    if (this.$can("manage", "/Method/GetAllMethods")) {
      this.$store.dispatch(GET_ALL_METHODS).then((response) => {
        this.table = response;
      });
    }
  },
  methods: {
    logout() {
      this.$store.commit(PURGE_AUTH);
    },
  },
  computed: {
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
    skin() {
      return this.$store.state.appConfig.layout.skin;
    },
  },
};
</script>
