<template>
  <b-col cols="12" md="4" xl="6">
    <b-overlay
      :show="!currentUser"
      variant="dark"
      opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <b-card
        v-if="currentUser"
        text-variant="center"
        class="card card-congratulations"
      >
        <!-- images -->
        <b-img
          :src="require('@/assets/images/elements/decore-left.png')"
          class="congratulations-img-left"
        />
        <b-img
          :src="require('@/assets/images/elements/decore-right.png')"
          class="congratulations-img-right"
        />
        <!--/ images -->

        <b-avatar variant="primary" size="70" class="shadow mb-2">
          <feather-icon size="28" icon="AwardIcon" />
        </b-avatar>
        <h1 class="mb-1 mt-50 text-white">
          {{ $t("homepage.welcome_title", { user: currentUser.name }) }}
        </h1>
        <b-card-text class="m-auto w-75">
          {{ tradeName }}
        </b-card-text>
      </b-card>
    </b-overlay>
  </b-col>
</template>

<script>
import { BCol, BCard, BImg, BAvatar, BCardText, BOverlay } from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BCol,
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BOverlay,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    tradeName() {
      return this.currentUser.company?.company_trade_name;
    },
  },
};
</script>
